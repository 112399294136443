export const Config = {"api_host": "/api/v1/",
  "public_client": "walmart",
  "roi_cpa_campaign_margin": 5,
  "fullDateTimeFormat": "MM-dd-yyyy HH:mm",
  "dateTimeFormat": "MM-dd-yyyy",
  "defaultTimezone": "America/Chicago",
  "timeFormat": "HH:mm",
  "defaultCurrency": "MXN",
  "currencySymbol": "$",
  "search_debounce_delay": 750,
  "defaultLocale": "en-US",
  "basename": "/",
  "strategy_supply_type": "WAN",
  "support_link": "mailto:DSPcontacto@walmart.com?subject=Walmart – DSP – Contacto",
  "auth0": {
    "domain": "mediamath.auth0.com",
    "clientId": "I0bI0YsJFAmGK6jMn6u7Zu7gR45mhGvX"
  },
  "defaultReportColumns": {
    "app-transparency": [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "campaign_currency_code",
      "start_date",
      "end_date",
      "clicks",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "post_click_conversions",
      "post_view_conversions"
      ],
    "site-performance": [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "campaign_currency_code",
      "start_date",
      "end_date",
      "clicks",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "post_click_conversions",
      "post_view_conversions"
      ],
    "geo": [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "campaign_currency_code",
      "start_date",
      "end_date",
      "clicks",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "post_click_conversions",
      "post_view_conversions"
      ],
    "performance": [
      "agency_name",
      "advertiser_name",
      "campaign_name",
      "strategy_name",
      "campaign_currency_code",
      "start_date",
      "end_date",
      "clicks",
      "ctr",
      "impressions",
      "total_conversions",
      "total_spend",
      "total_spend_cpa",
      "total_spend_cpc",
      "total_spend_cpm",
      "post_click_conversions",
      "post_view_conversions",
      "client_margin_share",
      "client_margin_share_usd",
      "platform_access_fee",
      "platform_access_fee_usd"
      ],
  },
  "hideReportColumns": {
    "app-transparency": ["video_close", "video_collapse", "video_companion_clicks", "video_companion_ctr", "video_companion_impressions"],
    "site-performance": ["video_close", "video_collapse", "video_companion_clicks", "video_companion_ctr", "video_companion_impressions"],
    "geo": ["video_close", "video_collapse", "video_companion_clicks", "video_companion_ctr", "video_companion_impressions"],
    "performance": ["concept_id", "concept_name", "client_product_estimate", "io_number", "prisma_campaign_id", "tpas_placement_id", "tpas_placement_name", "3pa_conversions", "3pa_media_cost_cpa", "3pa_media_cost_cpa_usd", "3pa_media_cost_roi", "3pa_rr_per_1k_imps", "3pa_total_ad_cost_cpa", "3pa_total_ad_cost_cpa_usd", "3pa_total_ad_cost_roi", "3pa_total_aov", "3pa_total_aov_usd", "3pa_total_revenue", "3pa_total_revenue_usd", "3pa_total_spend_cpa", "3pa_total_spend_cpa_usd", "3pa_total_spend_roi", "dynamic_creative_cost", "dynamic_creative_cost_usd", "facebook_actions", "facebook_app_installs", "facebook_likes", "facebook_page_engagement", "facebook_post_comments", "facebook_post_engagement", "facebook_post_reactions", "facebook_post_shares", "identity_fee", "identity_fee_usd", "managed_service_fee", "managed_service_fee_usd", "measurement_cost_usd", "mm_margin_share", "mm_margin_share_usd", "post_click_aov", "post_click_aov_usd", "privacy_compliance_cost", "privacy_compliance_cost_usd", "source_media_enablement_fee", "source_media_enablement_fee_usd", "source_platform_access_fee", "source_platform_access_fee_usd", "total_aov", "total_aov_usd", "udi_data_cost", "udi_data_cost_usd", "uniques", "video_close", "video_collapse", "video_companion_clicks", "video_companion_ctr", "video_companion_impressions", "video_engaged_impressions", "video_engaged_rate", "video_expand"],
  },
  "reportingRowsPerPage": 10
};
